import React from "react";
const blogs = require("./blogs.json");
import Parser from "rss-parser";

async function parseFeedByUrl(url) {
  try {
    let parser = new Parser();
    const feed = await parser.parseURL(url);

    return feed || null;
  } catch (error) {
    console.error(error);
  }
  return null;
}

async function fetchBlogs() {
  let blogs = []
  try {

    const gitPiper = await parseFeedByUrl("https://www.gitpiper.com/rss/feed.xml")
    gitPiper?.items?.map(g => {
      blogs.push({ title: g.title, link: g.link })
    })
    const skillPiper = await parseFeedByUrl("https://www.skillpiper.com/rss/feed.xml")
    skillPiper?.items?.map(g => {
      blogs.push({ title: g.title, link: g.link })
    })

  } catch (error) {
    console.error(error)
  }
  return blogs
}

class BlogModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: blogs,

    };
  }
  componentDidMount() {
    fetchBlogs().then(extraBlogs => {
      if (extraBlogs) {
        this.setState({ blogs: this.state.blogs.concat(extraBlogs) })
      }
    }).catch((error) => {
      console.error(error)
    })
  }

  render() {
    const title = this.props.label;
    return (
      <ul>
        {this.state.blogs.map((item) => {
          if (item.title != title) {
            return (
              <MyAppChild
                key={item.title}
                label={item.title}
                value={item.link}
              />
            );
          }
        })}
      </ul>
    );
  }
}

class MyAppChild extends React.Component {
  render() {
    return (
      <li>
        <a href={this.props.value}>
          {this.props.label} in {new Date().getFullYear()}
        </a>
      </li>
    );
  }
}

export default BlogModel;
