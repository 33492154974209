import { Link } from "gatsby";
import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { trackEvent } from "../../util/google-analytics";
import { getUpdatedDate } from "../../util/helper";
import Affiliate from "../Affiliate";
import CarbonAds from "../CarbonAds/carbonads";
// import CarbonAdsCustom from "../CarbonAdsCustom/index"
import Disqus from "../Disqus/Disqus";
import EmailList from "../EmailList";
import BlogModel from "./blogModel";
import "./style.scss";
class PostTemplateDetails extends React.Component {
  render() {
    const { subtitle, author, url } = this.props.data.site.siteMetadata;
    const post = this.props.data.markdownRemark;

    const date = post.frontmatter.date;
    const postTitle = post?.frontmatter?.showYear == false ? post.frontmatter.title : `${post.frontmatter.title
      } in ${new Date().getFullYear()}`;
    const tags = post.fields.tagSlugs;
    const postLink = `${url}${post.fields.slug}`;
    const readingTime = post.fields.readingTime.text;
    const kofiStyle = {
      height: "100px",
      border: "0",
      alt: "Buy Me a Coffee at ko-fi.com",
      marginTop: "10px",
    };
    const homeBlock = (
      <div>
        <div>
          <Link
            className="post-single__home-button"
            to="/"
            onClick={(e) =>
              trackEvent(e, "Post Page Home Link", "Click", "Back to home")
            }
          >
            ← Home
          </Link>
          {<div className="post-single__carbon-button">
            <CarbonAds></CarbonAds>
          </div>}

        </div>
      </div>
    );

    const tagsBlock = (
      <div className="post-single__tags">
        <h5>
          Any thoughts, let's discuss on{" "}
          <a
            href={`https://twitter.com/share?url=${postLink}&text=${post.frontmatter.title
              }&via=${author.twitter.replace("@", "")}`}
            target="_blank"
          >
            {" "}
            twitter
          </a>
        </h5>
        <ul className="post-single__tags-list">
          {tags &&
            tags.map((tag, i) => (
              <li
                className="post-single__tags-list-item"
                key={tag}
                onClick={(e) =>
                  trackEvent(
                    e,
                    "Tags From Post Page",
                    "Click",
                    `${post.frontmatter.tags[i]}`
                  )
                }
              >
                <Link to={tag} className="post-single__tags-list-item-link">
                  {post.frontmatter.tags[i]}
                </Link>
              </li>
            ))}
        </ul>
        {/* <div className="post-single__body"> */}
        <h2>
          Sharing this article is a great way to educate others like you just
          did.
        </h2>
        <br></br>
        <br></br>
        <ShareButton postLink={postLink} post={post} author={author} />

        {/* </div> */}
        <div>
          <h2>
            If you’ve enjoyed this issue, do consider subscribing to my
            newsletter.
          </h2>
        </div>
        <br></br>
        <div>
          <EmailList {...this.props} />
        </div>
      </div>
    );

    const commentsBlock = (
      <div>
        <Disqus
          postNode={post}
          siteMetadata={this.props.data.site.siteMetadata}
        />
      </div>
    );

    return (
      <div>
        {homeBlock}
        <div className="post-single">
          <div className="post-single__inner">
            <h1 className="post-single__title">{postTitle}</h1>
            <div
              className="post-single__date"
              style={{ textAlign: "center", marginTop: "1em" }}
            >
              <em>{readingTime}</em>
              <br></br>
              <em>Last updated: {getUpdatedDate(date)}</em>
              <br></br>
              <br></br>
              <ShareButton postLink={postLink} post={post} author={author} />
            </div>
            <div
              className="post-single__body"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>
          <div className="post-single__footer">
            {/* <div>
              <CarbonAdsCustom />
            </div> */}
            {tagsBlock}
            <h4 className="post-single__footer-text">
              {subtitle} <strong>{author.name}</strong> is on {" "}
              <a
                href={`https://twitter.com/${author.twitter}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a> and <a
                href={`${author.discord}`}
                target="_blank"
                rel="noopener noreferrer"
              >Discord</a>. Let's Connect!!
            </h4>
            <h4>
              <p>
                To read more such interesting topics, let's go <a href="/">Home</a>
              </p>
            </h4>
            <h2>More Products from the maker of Box Piper:</h2>
            <h3>
              <p style={{ lineHeight: "30px" }}>
                Follow <a href="https://www.instagram.com/gitpiper/">GitPiper</a> Instagram account. GitPiper is the worlds biggest repository of programming and technology resources. There is nothing you can't find on <a href="https://www.gitpiper.com/">GitPiper</a>.
              </p>
            </h3>
            <h3>
              <p style={{ lineHeight: "30px" }}>
                Follow <a href="https://sharktankseason.com/">SharkTankSeason.com.</a> Dive into the riveting world of Shark Tank Seasons. Explore episodes, pitches, products, investment details, companies, seasons and stories of entrepreneurs seeking investment deals from sharks. Get inspired today!.
              </p></h3>
            <br></br>
            <Affiliate />
            <div>
              <h2 style={{ textAlign: "center" }}>
                More Blogs from the house of Box Piper:
              </h2>
              <BlogModel
                key={post.frontmatter.title}
                label={post.frontmatter.title}
              ></BlogModel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ShareButton = ({ postLink, post, author }) => {
  return (
    <div style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}>
      <TwitterShareButton
        url={postLink}
        title={post.frontmatter.title}
        via={author.twitter.replace("@", "")}
      >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      {"  "}
      <LinkedinShareButton
        url={postLink}
        title={post.frontmatter.title}
        summary={post.frontmatter.description}
      >
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
      {"  "}
      <WhatsappShareButton url={postLink} title={post.frontmatter.title}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      {"  "}
      <FacebookShareButton url={postLink} quote={post.frontmatter.title}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      {"  "}
      <RedditShareButton url={postLink} title={post.frontmatter.title}>
        <RedditIcon size={32} round={true} />
      </RedditShareButton>
      {"  "}
      <TelegramShareButton url={postLink} title={post.frontmatter.title}>
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>
      {"  "}
      <InstapaperShareButton
        url={postLink}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      >
        <InstapaperIcon size={32} round={true} />
      </InstapaperShareButton>
      {"  "}
      <LivejournalShareButton
        url={postLink}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      >
        <LivejournalIcon size={32} round={true} />
      </LivejournalShareButton>
      {"  "}
      <MailruShareButton
        url={postLink}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      >
        <MailruIcon size={32} round={true} />
      </MailruShareButton>
      {"  "}
      <PocketShareButton url={postLink} title={post.frontmatter.title}>
        <PocketIcon size={32} round={true} />
      </PocketShareButton>
      {"  "}
      <EmailShareButton url={postLink} subject={post.frontmatter.title}>
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
    </div>
  );
};
export default PostTemplateDetails;
