import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PostTemplateDetails from "../components/PostTemplateDetails";
class PostTemplate extends React.Component {
  render() {
    const { url, title, subtitle, author } = this.props.data.site.siteMetadata;
    const origin = typeof window !== "undefined" ? window.location.origin : "";
    const post = this.props.data.markdownRemark;
    const { title: postTitle, description: postDescription, showYear } = post.frontmatter;
    const postFields = post.fields;
    const description = postDescription !== null ? postDescription : subtitle;
    const pageTitle = showYear == false ? postTitle : `${postTitle} in ${new Date().getFullYear()}`;
    const profilePic = require(`../pages/article-image/og-image/${postTitle.split("'").join("").split("?").join("").split("#").join("no. ")}.jpg`);
    console.log("profilePic", profilePic)
    const metaTitle = `${pageTitle} - ${author.name}`;
    return (
      <Layout>
        <div>
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={`${url}${postFields.slug}`} />
            <meta property="og:url" content={`${url}${postFields.slug}`} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`${origin}${profilePic}`} />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:site" content={author.twitter} />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={description} />
            {/* <meta
              name="twitter:image"
              content={`${origin}${encodeURI(
                profilePic
              )}?${new Date().getTime()}`}
            /> */}
            <meta name="monetization" content="$ilp.uphold.com/QzG6Ww2rKUzq" />
          </Helmet>
          <PostTemplateDetails {...this.props} />
        </div>
      </Layout>
    );
  }
}

export default PostTemplate;

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        author {
          name
          twitter
          discord
        }
        disqusShortname
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        title
        tags
        date
        description
        path
        showYear
      }
    }
  }
`;
