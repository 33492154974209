import React from "react";
const affiliateStyle = {
  alt: "Box Piper Affiliate",
  marginTop: "0.625rem",
  marginRight: "auto",
  marginLeft: "auto",
  display: "block",
};
class Affiliate extends React.Component {
  render() {
    return (
      <div>
        <ScraperApi />
      </div>
    );
  }
}

export default Affiliate;

const ScraperApi = () => {
  return (
    <div>
      <a
        target="_blank"
        rel="nofollow"
        href="https://www.scraperapi.com/?fp_ref=box-piper-app"
      >
        <img
          src="/assets/scraperapi-1.gif"
          alt="Scraper API"
          style={affiliateStyle}
        />
      </a>
    </div>
  );
};

const GrowSurf = () => {
  return (
    <div>
      <a
        target="_blank"
        rel="nofollow"
        href="https://growsurf.com/?via=boxpiper"
      >
        <img src="/GrowSurf.png" alt="GrowSurf" style={affiliateStyle} />
      </a>
    </div>
  );
};
const Norton = () => {
  return (
    <div>
      <a
        target="_blank"
        rel="nofollow"
        href="https://ad.admitad.com/g/3e3gujtyuz635d0cce2c11184c9701/?i=4"
      >
        <img
          width="200"
          height="200"
          border="0"
          src="https://ad.admitad.com/b/gmhqr9slbr635d0cce2c11184c9701/"
          alt="Norton [CPS] WW"
          style={affiliateStyle}
        />
      </a>
    </div>
  );
};
const Wise = () => {
  return (
    <a
      href="https://wise.prf.hn/click/camref:1101ljeyk/creativeref:1100l60334"
      target="_blank"
    >
      <img src="/wise.png" border="0" />
    </a>
  );
};
const Consultation = () => {
  return (
    <div>
      <a href="https://gum.co/yfWxj" target="_blank">
        <img src="https://cdn.hashnode.com/res/hashnode/image/upload/v1608833592346/d26Wh7xNv.png?auto=compress"></img>
      </a>
    </div>
  );
};
const Frontendor = () => {
  return (
    <div>
      <a href="https://gumroad.com/a/717878387" target="_blank">
        <img src="https://cdn.hashnode.com/res/hashnode/image/upload/v1610885826357/zYEjzR-BW.png?auto=compress"></img>
      </a>
    </div>
  );
};

const TransistorFM = () => {
  return (
    <a href="https://transistor.fm/?via=boxpiper" target="_blank">
      <img src="https://cdn.hashnode.com/res/hashnode/image/upload/v1609672370860/iyqsVCW35.png?auto=compress"></img>
    </a>
  );
};

const Sertifier = () => {
  return (
    <div style={{ display: "inline-block" }}>
      <a href="https://sertifier.com?fpr=box-piper-app49" target="_blank">
        <img src="https://cdn.hashnode.com/res/hashnode/image/upload/v1609672584798/nQmRvVwMt.png?auto=compress" />
      </a>
    </div>
  );
};

const Podia = () => {
  return;
};
const Fiverr = () => {
  return (
    <div>
      <a
        href="https://track.fiverr.com/visit/?bta=203854&nci=7338"
        target="_blank"
        rel="noopener"
      >
        <img
          border="0"
          src="https://fiverr.ck-cdn.com/tn/serve/?cid=10640980"
        />
      </a>
    </div>
  );
};
